import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import { DatePicker } from "@material-ui/lab";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { myTaskPortal } from "../../../api/project/Project";
import { Link as RouterLink } from "react-router-dom";

const markRed = (date, status) => {
  if (status === 3) {
    //complete
    return false;
  }
  const today = moment();
  const taskDate = moment(date, "YYYY-MM-DD");
  // If task data is less than 7 days from today or date is in past then mark it red
  if (taskDate.diff(today, "days") <= 7 || taskDate.diff(today, "days") < 0) {
    return true;
  }
  return false;
};

const MyAllTaskPortal = () => {
  const [allTasksReference, setAllTasksReference] = useState([]);
  const [allTasks, setAllTasks] = useState([]);
  const [filter, setFilter] = useState("");
  const [status, setStatus] = useState(0);
  const [filterStartDate, setFilterStartDate] = useState(null);
  const [filterEndDate, setFilterEndDate] = useState(null);

  useEffect(() => {
    getMyTaskList();
  }, []);

  useEffect(() => {
    sort();
  }, [filter, filterStartDate, filterEndDate]);

  const getMyTaskList = async () => {
    const res = await myTaskPortal(filter);
    if (res?.data?.data?.values) {
      const obj = res.data.data.values;

      setAllTasks(Object.values(obj));
      setAllTasksReference(Object.values(obj));
    }
    console.log(res);
  };

  // Sort allTasks by filter
  const sort = () => {
    // Sort all tasks array by project name
    let newArray = [...allTasksReference];
    if (filter === "project_name") {
      newArray = allTasksReference.sort((a, b) => {
        if (a.project_name > b.project_name) {
          return 1;
        }
        if (a.project_name < b.project_name) {
          return -1;
        }
        return 0;
      });
      setAllTasks([...newArray]);
    }
    // Sort all tasks array by project start date
    else if (filter === "project_start_date") {
      newArray = allTasksReference.sort((a, b) => {
        if (a.project_start_date > b.project_start_date) {
          return 1;
        }
        if (a.project_start_date < b.project_start_date) {
          return -1;
        }
        return 0;
      });
      setAllTasks([...newArray]);
    }
    // Sort all tasks array by project end date
    else if (filter === "project_end_date") {
      newArray = allTasksReference.sort((a, b) => {
        if (a.project_end_date > b.project_end_date) {
          return 1;
        }
        if (a.project_end_date < b.project_end_date) {
          return -1;
        }
        return 0;
      });
      setAllTasks([...newArray]);
    } else if (filter === "status") {
      newArray = allTasksReference.sort((a, b) => {
        if (a.project_status > b.project_status) {
          return 1;
        }
        if (a.project_status < b.project_status) {
          return -1;
        }
        return 0;
      });
      setAllTasks([...newArray]);
    }

    // If startDate is not null the project_start_date should be greater than or equal to startDate
    if (filterStartDate) {
      console.log("filterStartDate", newArray);
      newArray = newArray.filter((task) => {
        return (
          moment(task.project_start_date, "YYYY-MM-DD").diff(
            moment(filterStartDate, "YYYY-MM-DD"),
            "days"
          ) >= 0
        );
      });
    }

    // If endDate is not null the project_end_date should be less than or equal to endDate
    if (filterEndDate) {
      newArray = newArray.filter((task) => {
        return (
          moment(task.project_end_date, "YYYY-MM-DD").diff(
            moment(filterEndDate, "YYYY-MM-DD"),
            "days"
          ) <= 0
        );
      });
    }

    setAllTasks([...newArray]);
  };

  const checkTaskStartAndEndDate = (task) => {
    if (filterStartDate) {
      if (
        moment(task.task_start_date, "YYYY-MM-DD").diff(
          moment(filterStartDate, "YYYY-MM-DD"),
          "days"
        ) < 0
      ) {
        return false;
      }
    }
    if (filterEndDate) {
      if (
        moment(task.task_end_date, "YYYY-MM-DD").diff(
          moment(filterEndDate, "YYYY-MM-DD"),
          "days"
        ) > 0
      ) {
        return false;
      }
    }
    return true;
  };

  const checkForProjectsHasFilteredStatus = (task) => {
    if (status === 0) {
      return true;
    }
    let flag = false;
    task.tasks.forEach((task) => {
      if (task.task_status === status) {
        flag = true;
      }
    });
    return flag;
  };

  return (
    <Box>
      <Box sx={{ mb: 3, display: "flex" }}>
        <DatePicker
          label="Start Start"
          autoOk={true}
          className="pt-dtpicker"
          fullWidth
          onChange={(date) => setFilterStartDate(date)}
          helperText={null}
          value={filterStartDate}
          InputProps={{
            disableUnderline: true,
          }}
          name="filterStartDate"
          renderInput={(inputProps) => (
            <TextField
              fullWidth
              helperText={null}
              variant="outlined"
              {...inputProps}
              sx={{
                width: "200px",
                mr: 1,
              }}
            />
          )}
        />
        <DatePicker
          label="End Date"
          autoOk={true}
          className="pt-dtpicker"
          fullWidth
          onChange={(date) => setFilterEndDate(date)}
          helperText={null}
          value={filterEndDate}
          InputProps={{
            disableUnderline: true,
          }}
          minDate={filterStartDate}
          name="filterEndDate"
          renderInput={(inputProps) => (
            <TextField
              fullWidth
              helperText={null}
              variant="outlined"
              {...inputProps}
              sx={{
                width: "200px",
                mr: 1,
              }}
            />
          )}
        />
        <FormControl>
          <InputLabel id="select-status" sx={{ color: "#bbb" }}>
            Sort By{" "}
          </InputLabel>
          <Select
            label="Sort By"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            sx={{
              width: "200px",
              // height: "40px",
            }}
          >
            <MenuItem selected value={""}>
              None
            </MenuItem>
            <MenuItem value={"project_name"}>Project Name</MenuItem>
            <MenuItem value={"status"}>Status</MenuItem>
          </Select>
        </FormControl>
        <FormControl
          sx={{
            ml: 1,
          }}
        >
          <InputLabel id="select-status" sx={{ color: "#bbb" }}>
            Status
          </InputLabel>
          <Select
            label="Sort By"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            sx={{
              width: "200px",
            }}
          >
            <MenuItem selected value={"0"}>
              All
            </MenuItem>
            <MenuItem value={1}>TO DO</MenuItem>
            <MenuItem value={2}>Work In Progress</MenuItem>
            <MenuItem value={3}>Complete</MenuItem>
            <MenuItem value={4}>On Hold</MenuItem>
          </Select>
        </FormControl>
      </Box>
      {allTasks.length ? (
        <>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Project Name</TableCell>
                <TableCell>Task Name</TableCell>
                <TableCell>Start Date</TableCell>
                <TableCell>End Date</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allTasks.map((task) => (
                <React.Fragment key={task.project_id}>
                  {checkForProjectsHasFilteredStatus(task) ? (
                    <TableRow
                      sx={{
                        backgroundColor: "#e0e0e0",
                        borderBottom: "2px solid #fff",
                        td: {
                          py: 1,
                          fontWeight: "bold",
                          color: "#222",
                        },
                      }}
                    >
                      <TableCell>{task.project_name}</TableCell>
                      <TableCell></TableCell>
                      <TableCell>{task.project_start_date}</TableCell>
                      <TableCell>{task.project_end_date}</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  ) : (
                    ""
                  )}
                  {task.tasks.map((task) => (
                    <React.Fragment key={task.task_id}>
                      {checkTaskStartAndEndDate(task) &&
                        (status === 0 || status === task.task_status) && (
                          <TableRow
                            sx={{
                              backgroundColor: "#fff",
                              td: { py: 1 },
                            }}
                          >
                            <TableCell></TableCell>
                            <TableCell>
                              <RouterLink
                                to={`/dashboard/projects/task/${task.task_id}`}
                                style={{ color: "#222" }}
                              >
                              {task.task_name}
                              </RouterLink>
                              </TableCell>
                            <TableCell>{task.task_start_date}</TableCell>
                            <TableCell
                              sx={{
                                color: markRed(
                                  task.task_end_date,
                                  task.task_status
                                )
                                  ? "red"
                                  : "#222",
                              }}
                            >
                              {task.task_end_date}
                            </TableCell>
                            <TableCell>{task.task_status_name}</TableCell>
                          </TableRow>
                        )}
                    </React.Fragment>
                  ))}
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </>
      ) : (
        <Box
          sx={{
            textAlign: "center",
            mt: 2,
            color: "red",
            fontWeight: "bold",
            p: 4,
            background: "#eee",
          }}
        >
          No Task Found
        </Box>
      )}
    </Box>
  );
};

export default MyAllTaskPortal;
