import React, { useState, useEffect } from "react";
import {
  FormHelperText,
  TextField,
  Box,
  Button,
  Grid,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Autocomplete,
} from "@material-ui/core";
import * as Yup from "yup";
import { Formik } from "formik";
import useMounted from "../../../hooks/useMounted";
import toast from "react-hot-toast";
import DatePicker from "@material-ui/lab/DatePicker";
import {
  getTaskType,
  getUsersUnderAdmin,
  getTaskStatuses,
  getTaskPriorities,
  getProjectMilestones,
  editTask,
  getDropdownTasks,
} from "../../../api/project/Project";
import { makeStyles } from "@material-ui/core/styles";
import skills from "../../../@const/skills";
import moment from "moment";
import FileUploader from "../../FileUploader";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const ViewAndEditTaskModal = ({
  task,
  showEditTaskModal,
  setShowEditTaskModal,
  selectedProject,
  getTaskList,
  setaddAssignedToProjectMembers,
}) => {
  const mounted = useMounted();
  const classes = useStyles();
  const splitedStartDate = moment(task?.start_date)
    .format("DD-MM-YYYY")
    .split("-");
  const splitedEndDate = moment(task?.end_date).format("DD-MM-YYYY").split("-");
  const [editActive, setEditActive] = useState(false);

  const [formInitialValues, setFormInitialValues] = useState({
    taskId: task.id,
    taskName: task.name,
    taskType: task.get_type.id,
    skills: task?.skills?.replace(/[\[\]']+/g, "").split(",") || [],
    taskEstimation: task.estimate_hours,
    actualHours: task.actual_hours || 0,
    milestone: task.milestone || "",
    priority: task.priority,
    belongsTo: task.dependency || "",
    taskStatus: task.get_status.id,
    taskDescription: task?.description || "",
    recurringDays: task.recurring_days || "",
  });
  const [memberList, setMemberList] = useState([]);
  const [aallTasks, setAallTasks] = useState([]);
  // Create date from month [splitedStartDate[1]] day[splitedStartDate[0]] and year[splitedStartDate[2]]
  const dateTest = new moment(
    splitedStartDate[1] + "/" + splitedStartDate[0] + "/" + splitedStartDate[2]
  );

  const [taskStartDate, setTaskStartDate] = useState(moment(task.start_date));
  const [taskEndDate, setTaskEndDate] = useState(moment(task.end_date));
  const [taskTypes, setTaskTypes] = useState([]);
  const [taskStatuses, setTaskStatuses] = useState([]);
  const [milestoneTypes, setMilestoneTypes] = useState([]);

  const [taskPriorities, setTaskPriorities] = useState([]);
  const [taskAssignedTo, setTaskAssignedTo] = useState(task.get_assigned_to);

  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    getTaskDropdownlist();
    getTaskPriorityTypes();
    getTaskStatusTypes();
    getMemberList();
    getTaskTypes();
    getMilestoneTypes();
  }, []);

  const getMemberList = async () => {
    const res = await getUsersUnderAdmin();
    if (res.data.success) {
      setMemberList(res.data.data.peopleDropdown);
    } else {
      console.log("No Members");
    }
  };

  const getTaskDropdownlist = async () => {
    const res = await getDropdownTasks({ projectId: selectedProject.id });
    if (res.data.success) {
      setAallTasks(res.data.data.tasks.data);
    }
  };

  const getTaskTypes = async () => {
    const res = await getTaskType();
    if (res.data.success) {
      setTaskTypes(res.data.data.taskTypes);
    }
  };

  const getMilestoneTypes = async () => {
    const res = await getProjectMilestones({ projectId: selectedProject.id });
    if (res.data.success) {
      setMilestoneTypes(res.data.data.milestoneDropdownData);
    }
  };

  const getTaskStatusTypes = async () => {
    const res = await getTaskStatuses();
    if (res.data.success) {
      setTaskStatuses(res.data.data.taskStatuses);
    }
  };

  const getTaskPriorityTypes = async () => {
    const res = await getTaskPriorities();
    if (res.data.success) {
      setTaskPriorities(res.data.data.taskPriorities);
    }
  };

  const handleUChange = (e, val) => {
    setTaskAssignedTo(val || "");
  };

  return (
    <>
      <Dialog
        open={showEditTaskModal}
        onClose={() => setShowEditTaskModal(false)}
      >
        {!editActive && (
          <Box
            sx={{
              px: 3,
              py: 2,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6">{editActive ? "EDIT" : "VIEW"}</Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setEditActive(true)}
            >
              Edit
            </Button>
          </Box>
        )}
        <Formik
          initialValues={{
            ...formInitialValues,
            submit: null,
          }}
          validationSchema={Yup.object().shape({
            taskName: Yup.string().max(255).required("Task Name is required"),
            taskType: Yup.string().max(255).required("Task Type is required"),
            skills: Yup.array().nullable(),
            priority: Yup.string().max(255).nullable(),
            taskStatus: Yup.string().max(255).required("Task Type is required"),
            taskDescription: Yup.string().max(255).nullable(),
            recurringDays: Yup.number()
              .typeError("Days Must be in number"),
            taskEstimation: Yup.number()
              .typeError("Hours Must be in number")
              .max(255)
              .required("Estimated hours is required"),
            actualHours: Yup.number()
              .typeError("Hours Must be in number")
              .max(255)
              .required("Actual hours is required"),
            milestone: Yup.string().max(255).nullable(),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            let data = new FormData();
            data.append("task_doc", selectedFile);
            data.append("task_id", values.taskId);
            data.append("proj_id", selectedProject.id);
            data.append("task_name", values.taskName);
            data.append("task_type", values.taskType);
            data.append("task_skills", `[${values.skills.toString()}]`);
            data.append("task_estimate", values.taskEstimation);
            data.append("actual_hours", values.actualHours);
            data.append("dependent_task_id", values.belongsTo || "");
            data.append("person_id", taskAssignedTo?.id || "");
            data.append(
              "start_date",
              moment(taskStartDate).format("YYYY/MM/DD/")
            );
            data.append("end_date", moment(taskEndDate).format("YYYY/MM/DD/"));
            data.append("task_milestone", +values.milestone || "");
            data.append("task_priority", values.priority || "");
            data.append("recurring_days", values.recurringDays || "");
            data.append("task_status", values.taskStatus);
            data.append("desc", values.taskDescription || "");
            data.append("taskDocId", "");

            try {
              const res = await editTask(data, task.id);

              if (res.data.success) {
                toast.success("Task edited successfully");
                if (getTaskList) {
                  getTaskList();
                }
                if (res.data.data.hasAssignedToNonMember) {
                  setaddAssignedToProjectMembers(res.data.data.assignedToId);
                }
                setShowEditTaskModal(false);
              }
              if (mounted.current) {
                setStatus({ success: true });
                setSubmitting(false);
              }
            } catch (err) {
              console.error(err);
              toast.error("Something went wrong");
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form
              noValidate
              onSubmit={handleSubmit}
              enctype="multipart/form-data"
            >
              <DialogTitle>Edit Task</DialogTitle>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Task Name"
                      required
                      error={Boolean(touched.taskName && errors.taskName)}
                      fullWidth
                      helperText={touched.taskName && errors.taskName}
                      margin="normal"
                      name="taskName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.taskName}
                      variant="outlined"
                      sx={{ mt: 0 }}
                      disabled={!editActive}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box sx={{ width: "100%" }}>
                      {taskTypes.length > 0 && (
                        <FormControl className={classes.formControl}>
                          <InputLabel id="select-status" sx={{ color: "#bbb" }}>
                            Task Type{" "}
                            <Box component="span" sx={{ color: "#f00" }}>
                              *
                            </Box>
                          </InputLabel>
                          <Select
                            disabled={!editActive}
                            labelId="select-status"
                            label="Task Type *"
                            name="taskType"
                            value={values.taskType}
                            error={Boolean(touched.taskType && errors.taskType)}
                            fullWidth
                            helperText={touched.taskType && errors.taskType}
                            required
                            placeholder="Status"
                            onBlur={handleBlur}
                            onChange={handleChange}
                          >
                            <MenuItem value="">none</MenuItem>
                            {taskTypes.map((task) => (
                              <MenuItem key={task.id} value={task.id}>
                                {task.type}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Estimated Hours"
                      required
                      disabled={!editActive}
                      error={Boolean(
                        touched.taskEstimation && errors.taskEstimation
                      )}
                      fullWidth
                      helperText={
                        touched.taskEstimation && errors.taskEstimation
                      }
                      margin="normal"
                      name="taskEstimation"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.taskEstimation}
                      variant="outlined"
                      sx={{ mt: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Actual Hours"
                      required
                      disabled={!editActive}
                      error={Boolean(touched.actualHours && errors.actualHours)}
                      fullWidth
                      helperText={touched.actualHours && errors.actualHours}
                      margin="normal"
                      name="actualHours"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.actualHours}
                      variant="outlined"
                      sx={{ mt: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <DatePicker
                      label="Task Start Date *"
                      disabled={!editActive}
                      autoOk={true}
                      className="pt-dtpicker"
                      fullWidth
                      onChange={(date) => {
                        setTaskEndDate(date);
                        setTaskStartDate(date);
                      }}
                      value={taskStartDate}
                      name="taskStartDate"
                      renderInput={(inputProps) => (
                        <TextField
                          className="pt-datepicker-ip"
                          fullWidth
                          variant="outlined"
                          {...inputProps}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <DatePicker
                      label="Task End Date *"
                      disabled={!editActive}
                      autoOk={true}
                      className="pt-dtpicker"
                      fullWidth
                      onChange={(date) => setTaskEndDate(date)}
                      value={taskEndDate}
                      name="taskEndDate"
                      minDate={taskStartDate}
                      renderInput={(inputProps) => (
                        <TextField
                          fullWidth
                          className="pt-datepicker-ip"
                          variant="outlined"
                          {...inputProps}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box sx={{ width: "100%" }}>
                      {taskStatuses.length > 0 && (
                        <FormControl className={classes.formControl}>
                          <InputLabel id="select-status" sx={{ color: "#bbb" }}>
                            Task Status{" "}
                            <Box component="span" sx={{ color: "#f00" }}>
                              *
                            </Box>
                          </InputLabel>
                          <Select
                            labelId="select-status"
                            label="Task Status *"
                            disabled={!editActive}
                            name="taskStatus"
                            value={values.taskStatus}
                            error={Boolean(
                              touched.taskStatus && errors.taskStatus
                            )}
                            fullWidth
                            helperText={touched.taskStatus && errors.taskStatus}
                            required
                            placeholder="Status"
                            onBlur={handleBlur}
                            onChange={handleChange}
                          >
                            <MenuItem value="">none</MenuItem>
                            {taskStatuses.map((prior) => (
                              <MenuItem key={prior.id} value={prior.id}>
                                {prior.status}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box sx={{ width: "100%" }}>
                      <FormControl className={classes.formControl}>
                        <InputLabel id="select-status" sx={{ color: "#bbb" }}>
                          Skills
                        </InputLabel>
                        <Select
                          disabled={!editActive}
                          labelId="select-status"
                          label="skills *"
                          name="skills"
                          value={values.skills}
                          error={Boolean(touched.skills && errors.skills)}
                          fullWidth
                          helperText={touched.skills && errors.skills}
                          placeholder="skills"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          multiple
                          className="multiselect-dropdown"
                        >
                          <MenuItem value="">none</MenuItem>
                          {skills.length > 0 &&
                            skills.map((skill) => (
                              <MenuItem
                                className="multiselect-dropdown-menu"
                                key={skill.value}
                                value={skill.value}
                              >
                                {skill.title}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      disablePortal
                      disabled={!editActive}
                      id="combo-box-demo"
                      blurOnSelect={true}
                      options={memberList}
                      onChange={handleUChange}
                      defaultValue={taskAssignedTo}
                      getOptionLabel={(option) =>
                        `${option.first_name} ${option.last_name}`
                      }
                      renderOption={(props, option) => {
                        return (
                          <li
                            {...props}
                            key={`${option.first_name} ${option.last_name} ${option.id}`}
                          >
                            {`${option.first_name} ${option.last_name}`}
                          </li>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Assigned To"
                          name="cPerson"
                          helperText={touched.cPerson && errors.cPerson}
                          error={touched.cPerson && errors.cPerson}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Box sx={{ width: "100%" }}>
                      <FormControl className={classes.formControl}>
                        <InputLabel id="select-status" sx={{ color: "#bbb" }}>
                          Milestone{" "}
                        </InputLabel>
                        <Select
                          disabled={!editActive}
                          labelId="select-status"
                          label="Milestone"
                          name="milestone"
                          value={values.milestone}
                          error={Boolean(touched.milestone && errors.milestone)}
                          fullWidth
                          helperText={touched.milestone && errors.milestone}
                          required
                          placeholder="Status"
                          onBlur={handleBlur}
                          onChange={handleChange}
                        >
                          <MenuItem value="">none</MenuItem>
                          {milestoneTypes.map((mile) => (
                            <MenuItem key={mile.id} value={mile.id}>
                              {mile.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Box sx={{ width: "100%" }}>
                      {taskPriorities.length > 0 && (
                        <FormControl className={classes.formControl}>
                          <InputLabel id="select-status" sx={{ color: "#bbb" }}>
                            Task Priority{" "}
                          </InputLabel>
                          <Select
                            labelId="select-status"
                            disabled={!editActive}
                            label="Task Priority *"
                            name="priority"
                            value={values.priority}
                            error={Boolean(touched.priority && errors.priority)}
                            fullWidth
                            helperText={touched.priority && errors.priority}
                            placeholder="Status"
                            onBlur={handleBlur}
                            onChange={handleChange}
                          >
                            <MenuItem value="">none</MenuItem>
                            {taskPriorities.map((prior) => (
                              <MenuItem key={prior.id} value={prior.id}>
                                {prior.priority}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Box sx={{ width: "100%" }}>
                      {aallTasks?.length > 0 && (
                        <FormControl className={classes.formControl}>
                          <InputLabel id="select-status" sx={{ color: "#bbb" }}>
                            Parent Task{" "}
                          </InputLabel>
                          <Select
                            labelId="select-status"
                            label="Parent Task *"
                            name="belongsTo"
                            disabled={!editActive}
                            value={values.belongsTo}
                            error={Boolean(
                              touched.belongsTo && errors.belongsTo
                            )}
                            fullWidth
                            helperText={touched.belongsTo && errors.belongsTo}
                            placeholder="Status"
                            onBlur={handleBlur}
                            onChange={handleChange}
                          >
                            <MenuItem value="">none</MenuItem>
                            {aallTasks?.map((task) => (
                              <MenuItem key={task.id} value={task.id}>
                                {task.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Recurring days"
                      error={Boolean(touched.recurringDays && errors.recurringDays)}
                      fullWidth
                      helperText={touched.recurringDays && errors.recurringDays}
                      margin="normal"
                      name="recurringDays"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.recurringDays}
                      variant="outlined"
                      sx={{ mt: 0 }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FileUploader
                      onFileSelectSuccess={(file) => setSelectedFile(file)}
                      onFileSelectError={({ error }) => alert(error)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      label="Task Description"
                      disabled={!editActive}
                      error={Boolean(
                        touched.taskDescription && errors.taskDescription
                      )}
                      fullWidth
                      helperText={
                        touched.taskDescription && errors.taskDescription
                      }
                      margin="normal"
                      name="taskDescription"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.taskDescription}
                      variant="outlined"
                      sx={{ mt: 0 }}
                    />
                  </Grid>
                </Grid>

                {errors.submit && (
                  <Box sx={{ mt: 3 }}>
                    <FormHelperText error>{errors.submit}</FormHelperText>
                  </Box>
                )}
              </DialogContent>
              <DialogActions>
                <Box sx={{ display: "flex", mr: "auto", px: 2, pb: 1 }}>
                  <Box>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Save
                    </Button>
                  </Box>
                  <Box sx={{ ml: 1 }}>
                    <Button
                      disabled={isSubmitting}
                      size="large"
                      variant="contained"
                      onClick={() => setShowEditTaskModal(false)}
                      sx={{ backgroundColor: "#C0CBDE" }}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Box>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export default ViewAndEditTaskModal;
