import DepartmentIcon from "@material-ui/icons/Business";
import PeopleIcon from "@material-ui/icons/GroupsOutlined";
import BudgetIcon from "@material-ui/icons/MonetizationOnOutlined";
import Portfolio from "../icons/sidebar/Portfolio";
import Project from "../icons/sidebar/Project";
import TimeIcon from "../icons/sidebar/Time";
import CalendarIcon from "../icons/Calendar";
const userRoutes = [
  {
    items: [
      {
        title: "Portfolios",
        path: "/dashboard/portfolios/view-all-portfolios",
        icon: <Portfolio className="nav-left-icon" fontSize="small" />,
        children: [],
      },
      {
        title: "Projects",
        path: "/dashboard/projects/view-all-projects",
        icon: <Project className="nav-left-icon" fontSize="small" />,
        children: [
          { title: "View all", path: "/dashboard/projects/view-all-projects" },
          {
            title: "Project Intake",
            path: "/dashboard/score",
          },
          {
            title: "Project Template",
            path: "/dashboard/admin-setting/project-template",
          },
        ],
      },
      // {
      //   title: "Calendar",
      //   path: "/dashboard/calendar",
      //   icon: <Portfolio className="nav-left-icon" fontSize="small" />,
      //   children: [],
      // },
      {
        title: "People",
        path: "/dashboard/peoples/view-all",
        icon: <PeopleIcon className="nav-left-icon" fontSize="small" />,
        children: [
          {
            title: "View All",
            path: "/dashboard/peoples/view-all",
          },
          {
            title: "Utilization",
            path: "/dashboard/peoples/utilization",
          },
          {
            title: "My Task Portal",
            path: "/dashboard/projects/my-task-portal",
          },
        ],
      },
      {
        title: "Departments",
        path: "/dashboard/departments/view-all",
        icon: <DepartmentIcon className="nav-left-icon" fontSize="small" />,
        children: [],
      },
      {
        title: "Calendar",
        path: "/dashboard/calendar",
        icon: <CalendarIcon className="nav-left-icon" fontSize="small" />,
        children: [
          {
            title: "Calendar",
            path: "/dashboard/calendar",
          }
        ],
      },
      // {
      //   title: "Scheduling",
      //   path: "/dashboard/scheduling",
      //   icon: <PendingActionsIcon className="nav-left-icon" fontSize="small" />,
      //   children: [
      //     {
      //       title: "Utilisation",
      //       path: "/dashboard/scheduling/view-all",
      //     },
      //     {
      //       title: "Members Available",
      //       path: "/dashboard/scheduling/members-available",
      //     },
      //   ],
      // },
      {
        title: "Reports",
        path: "/dashboard/reports",
        icon: <BudgetIcon className="nav-left-icon" fontSize="small" />,
        children: [
          {
            title: "Project Report",
            path: "/dashboard/reports?p=project-status",
          },
        ],
      },
      {
        title: "Time",
        path: "/dashboard/time",
        icon: <TimeIcon className="nav-left-icon" fontSize="small" />,
        children: [
          {
            title: "Time Entry",
            path: "/dashboard/time/add-new",
          },
        ],
      },
    ],
  },
];

export default userRoutes;
